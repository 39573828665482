import { sendGTMEvent } from '@next/third-parties/google'
import { LangCookies } from 'contexts'
import { maxExpiry } from 'datetime'
import { GlobeIcon } from 'icons/GlobeIcon'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { useCookies } from 'react-cookie'
import { Box } from 'ui-v2/components/Box'
import { UnstyledButton } from 'ui-v2/components/Button'
import { Flex } from 'ui-v2/components/Flex'
import { Grid } from 'ui-v2/components/Grid'
import { Text } from 'ui-v2/components/Text'
import { buttonCx, contentsCx, globeWrapperCx, textWrapperCx } from './LanguageSwitch.css'

const locales = ['en', 'ja']
export interface LanguageSwitchProps {}
export const LanguageSwitch: FC<LanguageSwitchProps> = () => {
  const router = useRouter()
  const { lang } = useTranslation()
  const [_cookies, setCookie] = useCookies([LangCookies.NEXT_LOCALE])
  const safeLang = lang === 'ja' ? 'ja' : 'en'
  const selectLanguage = () => {
    const targetLang = locales.filter((lng) => lng !== lang).join()
    sendGTMEvent({ event: 'language_switcher', value: targetLang })
    setCookie(LangCookies.NEXT_LOCALE, targetLang, {
      path: '/',
      expires: maxExpiry,
    })
    router.replace(router.asPath, undefined, { locale: targetLang })
  }

  const textDark = 'colorsTextNeutralDefaultHighContrast'
  const textGray = 'colorsTextNeutralDefaultLowContrast'
  return (
    <Grid data-testid="language-switch" place="center">
      <UnstyledButton
        onPress={selectLanguage}
        data-cy="language-switch-button"
        data-lang={lang}
        className={({ isFocused, isFocusVisible }) =>
          buttonCx({ isFocusVisible: isFocused && isFocusVisible })
        }
      >
        <Flex className={contentsCx}>
          <Grid place="center">
            <Box className={globeWrapperCx({ lang: safeLang })}>
              <GlobeIcon size="md" color="colorsTextNeutralDefaultHighContrast" />
            </Box>
          </Grid>
          <Text variant="paragraphMd" className={textWrapperCx}>
            <Text variant="paragraphMd" color={lang === 'ja' ? textDark : textGray}>
              日本語
            </Text>
            <Text variant="paragraphMd" mb={lang === 'ja' ? '200' : '000'}>
              |
            </Text>
            <Text variant="paragraphMd" color={lang === 'en' ? textDark : textGray}>
              EN
            </Text>
          </Text>
        </Flex>
      </UnstyledButton>
    </Grid>
  )
}
