import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fnavs%2FTopNavButton.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81U3XKjIBS%2Bz1Nwk5k6s3Ri1iSWvsjedYig0iKHBUzs7uy774gaxWi7vds7gSPn%2Bzkfjy%2FpMc1ME6PfG4TONHsrDNSKEaRA8ed2DwzjZlxD7aRQfNzQlDGhCoIu1Dxg%2FHI6VuWFX2TUHma1sWAI0iCU42a8EBvKRG1nf1330fPmz%2BaxR7W%2FQxVUAwuqv%2F9HHBByvHGY8QwMdQLU0GuEm3yJ3OHj6ubqm14FcyVBFW1wBspx5QJ28V43M4rg%2F2PCaknfCSqMYO1GQXVXPpUvpPkUIQtSsBnsJPpUoF1f0mBbUgbX2Xm8ywPqR0%2F9HqIRDDteaUkdxxnIulKWoCTVDYpzc8dixRCEqBSFwsLxyhKU8cHj2ZCMeE7LeHrt2%2F7tsuSiKN24fq2tE%2Fn74Mu00wqAVbNVFn3VFsU%2FtyUOVE89yxxaqEHdr59dKkDCvHlTBFc8%2BSsWC8Phpv9aeF4ZhdbnpeiuKLsyOpPYdBM0Ns5848HT3tLe8XSakpu2s%2BzEuvkoLjer8aIKXbo1WNGNLj1bkLXjQXZzyX3LXEi5fIF1Bt74%2FKFJJme45xQHnM7gHFTz9xSbTo1h14EmCB%2F7tBmqbA6mIt1nq%2FQDPuy239BpGyEDrt1IDoyHM8O80D2KHmd30r0agwW3VPumw2pZIclzR9Bht10D9sMja3H8Bfv%2BtJMQBwAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var arrowCx = '_868crxc';
export var iconContainerCx = '_868crx7';
export var itemTitleCx = _7a468({defaultClassName:'_868crx8',variantClassNames:{isHovered:{true:'_868crx9'},isFocusVisible:{true:'_868crxa'}},defaultVariants:{},compoundVariants:[]});
export var linkButtonCx = _7a468({defaultClassName:'_868crx3',variantClassNames:{isTriggerActive:{true:'_868crx4'}},defaultVariants:{},compoundVariants:[]});
export var linkCx = '_868crx6';
export var menuItemsContainerCx = '_868crx5';
export var titleWithLinkCx = '_868crxb';
export var trapezoidCx = '_868crxd';
export var trapezoidWidthDiff = 'var(--_868crx0)';
export var triggerButtonCx = _7a468({defaultClassName:'_868crx1',variantClassNames:{isTriggerActive:{true:'_868crx2'}},defaultVariants:{},compoundVariants:[]});