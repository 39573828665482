import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type GlobeIconProps = SvgIconProps & TestProps

export const GlobeIcon: FC<GlobeIconProps> = ({ color = 'inherit', size = 'md', ...props }) => (
  <BaseIconWrapper {...props} data-testid="globe-icon" color={color} size={size}>
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M8.22265 1.58397C8.45241 1.4308 8.76285 1.49289 8.91603 1.72265L8.5 2C8.91603 1.72265 8.91603 1.72265 8.91603 1.72265L8.91689 1.72395L8.91848 1.72635L8.92379 1.73442L8.94261 1.76336C8.95867 1.78826 8.98162 1.82425 9.01047 1.87061C9.06814 1.96331 9.1495 2.09759 9.24662 2.26756C9.44067 2.60714 9.69874 3.09107 9.95691 3.67193C10.4693 4.82492 11 6.39922 11 8C11 9.60078 10.4693 11.1751 9.95691 12.3281C9.69874 12.9089 9.44067 13.3929 9.24662 13.7324C9.1495 13.9024 9.06814 14.0367 9.01047 14.1294C8.98162 14.1757 8.95867 14.2117 8.94261 14.2366L8.92379 14.2656L8.91848 14.2736L8.91689 14.276L8.91617 14.2771C8.91617 14.2771 8.91603 14.2774 8.5 14L8.91617 14.2771C8.763 14.5069 8.45241 14.5692 8.22265 14.416C7.99294 14.2629 7.93083 13.9526 8.08387 13.7228L8.08768 13.717L8.10231 13.6945C8.11555 13.674 8.13557 13.6426 8.16141 13.6011C8.21311 13.518 8.288 13.3945 8.37838 13.2363C8.55933 12.9196 8.80126 12.4661 9.04309 11.9219C9.53066 10.8249 10 9.39922 10 8C10 6.60078 9.53066 5.17508 9.04309 4.07807C8.80126 3.53393 8.55933 3.08036 8.37838 2.76369C8.288 2.60554 8.21311 2.482 8.16141 2.39892C8.13557 2.35739 8.11555 2.326 8.10231 2.30549L8.08768 2.28298L8.08442 2.27802C7.93137 2.04827 7.99294 1.73712 8.22265 1.58397Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M7.77735 14.416C7.54759 14.5692 7.23715 14.5071 7.08397 14.2774L7.5 14C7.08397 14.2774 7.08397 14.2774 7.08397 14.2774L7.08311 14.276L7.08152 14.2736L7.07621 14.2656L7.05739 14.2366C7.04133 14.2117 7.01838 14.1757 6.98953 14.1294C6.93186 14.0367 6.8505 13.9024 6.75338 13.7324C6.55933 13.3929 6.30126 12.9089 6.04309 12.3281C5.53065 11.1751 5 9.60078 5 8C5 6.39922 5.53065 4.82492 6.04309 3.67193C6.30126 3.09107 6.55933 2.60714 6.75338 2.26756C6.8505 2.09759 6.93186 1.96331 6.98953 1.87061C7.01838 1.82425 7.04133 1.78826 7.05739 1.76336L7.07621 1.73442L7.08152 1.72635L7.08311 1.72395L7.08383 1.72287C7.08383 1.72287 7.08397 1.72266 7.45548 1.97032L7.08383 1.72287C7.237 1.49311 7.54759 1.4308 7.77735 1.58397C8.00706 1.73712 8.06917 2.04745 7.91613 2.2772L7.91232 2.28298L7.89769 2.30549C7.88445 2.326 7.86443 2.35739 7.83859 2.39892C7.78689 2.482 7.712 2.60554 7.62162 2.76369C7.44067 3.08036 7.19874 3.53393 6.95691 4.07807C6.46934 5.17508 6 6.60078 6 8C6 9.39922 6.46934 10.8249 6.95691 11.9219C7.19874 12.4661 7.44067 12.9196 7.62162 13.2363C7.712 13.3945 7.78689 13.518 7.83859 13.6011C7.86443 13.6426 7.88445 13.674 7.89769 13.6945L7.91232 13.717L7.91558 13.722C8.06863 13.9517 8.00706 14.2629 7.77735 14.416Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M1.5 8C1.5 7.72386 1.72386 7.5 2 7.5H14C14.2761 7.5 14.5 7.72386 14.5 8C14.5 8.27614 14.2761 8.5 14 8.5H2C1.72386 8.5 1.5 8.27614 1.5 8Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
