import { remap } from 'object'
import { useCallback, useEffect, useState } from 'react'

/**
 *  Extracts the raw values of CSS variables from the DOM and updates them when they change.
 *
 * @param variables A record of CSS variables, the keys can be freely chosen, the values must be CSS variable strings
 * @returns a record of the raw values of the CSS variables
 */
export const useExtractRawValues = <T extends Record<string, string>>(variables: T) => {
  const initialValues = remap(variables, () => '')
  const [values, setValues] = useState<Record<keyof T, string>>(initialValues)
  const equalVariables = JSON.stringify(variables)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const extractRawValues = useCallback(() => {
    const computedStyle = getComputedStyle(document.documentElement)

    return remap(variables, (_, variable) => {
      const parsedVariable = variable.replace(/var\((.*?)(?:, (.*?))?\)/, '$1')
      return computedStyle.getPropertyValue(parsedVariable)
    })
    //Disabled to prevent infinite loop. Check for contents of variables object
  }, [equalVariables])

  useEffect(() => {
    const extractedValues = extractRawValues()
    setValues(extractedValues)
  }, [extractRawValues])

  useEffect(() => {
    const styleObserver = new MutationObserver(async () => {
      const extractedValues = extractRawValues()
      setValues(extractedValues)
    })

    styleObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      styleObserver.disconnect()
    }
  }, [extractRawValues])

  return values
}
