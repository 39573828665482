import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useResizeObserver } from 'hooks'
import { LinkIcon } from 'icons/LinkIcon'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ComponentType, FC, useId, useMemo, useRef } from 'react'
import {
  FocusScope,
  mergeProps,
  useFocusRing,
  useHover,
  useOverlayPosition,
  usePress,
} from 'react-aria'
import { isDefined, isNotEmpty } from 'typeguards'
import { Grid } from 'ui-v2/components/Grid'
import { Text } from 'ui-v2/components/Text'
import { sizeToCssValue } from 'ui-v2/utils/size-to-css-value'
import {
  arrowCx,
  iconContainerCx,
  itemTitleCx,
  linkButtonCx,
  linkCx,
  menuItemsContainerCx,
  titleWithLinkCx,
  trapezoidCx,
  trapezoidWidthDiff,
  triggerButtonCx,
} from './TopNavButton.css'
import { useTopNavContext } from './context'
import { getActivePath } from './get-active-path'

interface MenuItemProps {
  title: string
  sub?: string
  type: 'anchor' | 'link'
  href: string
  Icon: ComponentType<{ isActive: boolean }>
}

interface CoreNavButtonProps {
  id: string
  label: string
}

interface TopNavButtonWithContentProps extends CoreNavButtonProps {
  menuItems: MenuItemProps[]
  href?: never
}

interface TopNavButtonWithLinkProps extends CoreNavButtonProps {
  menuItems?: never
  href: string
}

export type TopNavButtonProps = TopNavButtonWithContentProps | TopNavButtonWithLinkProps

const MenuItem: FC<MenuItemProps> = ({ title, sub, Icon, href, type }) => {
  const { hoverProps, isHovered } = useHover({})
  const { focusProps, isFocusVisible } = useFocusRing()
  const mergedLinkProps = mergeProps(hoverProps, focusProps)

  const iconColor =
    isHovered || isFocusVisible
      ? 'colorsTextPrimaryDefaultLowContrast'
      : 'colorsTextNeutralDefaultLowContrast'

  return (
    <div role="menuitem">
      {type === 'link' ? (
        <NextLink href={href} {...mergedLinkProps} className={linkCx}>
          <Grid className={iconContainerCx}>
            <Icon isActive={isHovered || isFocusVisible} />
          </Grid>
          <div>
            <div className={itemTitleCx({ isHovered, isFocusVisible })}>{title}</div>
            <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
              {sub}
            </Text>
          </div>
        </NextLink>
      ) : (
        <a href={href} target="_blank" rel="noreferrer" {...mergedLinkProps} className={linkCx}>
          <Grid className={iconContainerCx}>
            <Icon isActive={isHovered || isFocusVisible} />
          </Grid>
          <div>
            <div className={titleWithLinkCx}>
              <div className={itemTitleCx({ isHovered, isFocusVisible })}>{title}</div>
              <LinkIcon size="sm" color={iconColor} />
            </div>
            <Text variant="paragraphMd" color="colorsTextNeutralDefaultLowContrast">
              {sub}
            </Text>
          </div>
        </a>
      )}
    </div>
  )
}

MenuItem.displayName = 'MenuItem'

export const TopNavButton: FC<TopNavButtonProps> = ({ label, menuItems, href, id }) => {
  const menuId = useId()
  const router = useRouter()
  const activeLink = useMemo(() => getActivePath(router.asPath), [router.asPath])
  const hasActiveMenuItem = menuItems?.some((item) => getActivePath(item.href || '') === activeLink)
  const isActiveWithoutMenuItems = activeLink === href

  const { openedMenuId, setOpenedMenuId } = useTopNavContext()
  const isOpen = openedMenuId === id
  const setIsOpen = (v: boolean) => setOpenedMenuId(v ? id : null)

  const triggerRef = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const { width: contentsWidth } = useResizeObserver(menuRef, [isOpen]) ?? { width: 0 }

  const { hoverProps: triggerHoverProps, isHovered } = useHover({
    onHoverChange: setIsOpen,
  })
  const { hoverProps: contentsHoverProps } = useHover({
    onHoverChange: setIsOpen,
  })
  const { focusProps, isFocusVisible } = useFocusRing()
  const { pressProps } = usePress({
    onPress: () => {
      setOpenedMenuId((prev) => (prev ? null : id))
    },
  })

  const { overlayProps, arrowProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: menuRef,
    offset: 8,
    placement: 'bottom',
    isOpen,
  })

  const linkProps = mergeProps(triggerHoverProps, focusProps)
  const mergedMenuTriggerProps = mergeProps(pressProps, triggerHoverProps, focusProps)
  const mergedMenuProps = mergeProps(overlayProps, contentsHoverProps)

  const onEscapeKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false)
    }
  }

  const isTriggerActive =
    isOpen || isHovered || isFocusVisible || hasActiveMenuItem || isActiveWithoutMenuItems

  return (
    <>
      {isNotEmpty(menuItems) ? (
        <>
          <button
            ref={triggerRef}
            className={triggerButtonCx({ isTriggerActive })}
            aria-expanded={isOpen}
            aria-controls={isOpen ? menuId : undefined}
            data-selected={isTriggerActive}
            {...mergedMenuTriggerProps}
          >
            <Text variant="paragraphLg" color="colorsTextNeutralDefaultHighContrast">
              {label}
            </Text>
          </button>

          {isOpen && (
            <FocusScope contain restoreFocus autoFocus>
              <div
                ref={menuRef}
                className={menuItemsContainerCx}
                onKeyDown={onEscapeKeyDown}
                role="menu"
                tabIndex={-1}
                id={menuId}
                {...mergedMenuProps}
                style={{ position: 'absolute' }}
              >
                <div
                  className={trapezoidCx}
                  style={{
                    ...assignInlineVars({
                      [trapezoidWidthDiff]: sizeToCssValue(contentsWidth),
                    }),
                  }}
                />
                <div {...arrowProps} className={arrowCx} />

                {menuItems.map((item, i) => (
                  <MenuItem key={i} {...item} />
                ))}
              </div>
            </FocusScope>
          )}
        </>
      ) : (
        isDefined(href) && (
          <NextLink
            href={href}
            passHref
            className={linkButtonCx({ isTriggerActive })}
            data-selected={isTriggerActive}
            {...linkProps}
          >
            <Text variant="paragraphLg" color="colorsTextNeutralDefaultHighContrast">
              {label}
            </Text>
          </NextLink>
        )
      )}
    </>
  )
}
