import { SystemStatus } from '@/contexts/SystemStatusContext'
import useTranslation from 'next-translate/useTranslation'
import { FC, useMemo } from 'react'
import { WEBSITE_STATUS_TRACKER_URL } from 'src/assets/links'
import { Dot } from 'ui-v2/components/Dot'
import { Text } from 'ui-v2/components/Text'
import { anchorCx } from './SystemStatusIndicator.css'

export interface SystemStatusIndicatorProps {
  status?: SystemStatus
}

export const d = {
  status: 'tensor-website-common:status.status',
  ok: 'tensor-website-common:status.ok',
  degraded: 'tensor-website-common:status.degraded',
  down: 'tensor-website-common:status.down',
}

export const SystemStatusIndicator: FC<SystemStatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation()
  const statusColor = useMemo(() => {
    switch (status) {
      case 'ok':
        return 'primary'
      case 'degraded':
        return 'warning'
      case 'down':
        return 'danger'
      default:
        return 'primary'
    }
  }, [status])

  const statusText = useMemo(() => {
    switch (status) {
      case 'ok':
        return t(d.ok)
      case 'degraded':
        return t(d.degraded)
      case 'down':
        return t(d.down)
      default:
        return t(d.ok)
    }
  }, [status, t])

  return (
    <a
      rel="noreferrer noopener"
      target="_blank"
      href={WEBSITE_STATUS_TRACKER_URL}
      className={anchorCx({ color: statusColor })}
    >
      <Text variant="labelMd" color="colorsTextNeutralDefaultHighContrast">
        {t(d.status)}
      </Text>
      <Dot size={'md'} color={statusColor} />
      <Text variant="paragraphMd" color="inherit">
        {statusText}
      </Text>
    </a>
  )
}
