import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type LinkIconProps = SvgIconProps & TestProps

export const LinkIcon: FC<LinkIconProps> = ({ color = 'inherit', size = 'md', ...props }) => (
  <BaseIconWrapper {...props} data-testid="link-icon" color={color} size={size}>
    <path
      d="M1 2.68966C1 1.75648 1.75648 1 2.68966 1H8.48276C8.88269 1 9.2069 1.32421 9.2069 1.72414C9.2069 2.12407 8.88269 2.44828 8.48276 2.44828H2.68966C2.55635 2.44828 2.44828 2.55634 2.44828 2.68966V13.3103C2.44828 13.4437 2.55634 13.5517 2.68966 13.5517H13.3103C13.4437 13.5517 13.5517 13.4437 13.5517 13.3103V7.51724C13.5517 7.11731 13.8759 6.7931 14.2759 6.7931C14.6758 6.7931 15 7.11731 15 7.51724V13.3103C15 14.2435 14.2435 15 13.3103 15H2.68966C1.75648 15 1 14.2435 1 13.3103V2.68966Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M14.3051 1.69485C14.5879 1.97765 14.5879 2.43615 14.3051 2.71894L8.02928 8.9948C7.74649 9.27759 7.28799 9.27759 7.0052 8.9948C6.7224 8.71201 6.7224 8.25351 7.0052 7.97072L13.2811 1.69485C13.5639 1.41206 14.0224 1.41206 14.3051 1.69485Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M10.6552 1.72414C10.6552 1.32421 10.9794 1 11.3793 1H13.7931C14.4597 1 15 1.54035 15 2.2069V4.62069C15 5.02062 14.6758 5.34483 14.2759 5.34483C13.8759 5.34483 13.5517 5.02062 13.5517 4.62069V2.44828H11.3793C10.9794 2.44828 10.6552 2.12407 10.6552 1.72414Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
